import React, {useEffect, useState} from "react";
import "./style.scss";
import Consulting from "../../components/Consulting/Consulting";
import Moment from 'react-moment';
import {Link} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";

import image1 from "../../assets/img/project/undraw_wedding_t1yl.png";
import image2 from "../../assets/img/project/undraw_select_house_qbag.png";
import image3 from "../../assets/img/project/undraw_agreement_aajr.png";
import image4 from "../../assets/img/project/undraw_gradma_wanr.png";
import image5 from "../../assets/img/project/undraw_Graduation_ktn0.png";
import image6 from "../../assets/img/project/undraw_personal_finance_tqcd.png";

const Home = () => {
    const projects = [
        {
            id: 1,
            title: "결혼자금 만들기",
            image: image1
        },
        {
            id: 2,
            title: "내집 마련하기",
            image: image2
        },
        {
            id: 3,
            title: "보장자산 만들기",
            image: image3
        },
        {
            id: 4,
            title: "은퇴 노후 자금 만들기",
            image: image4
        },
        {
            id: 5,
            title: "자녀교육 자금 만들기",
            image: image5
        },
        {
            id: 6,
            title: "종자돈 1억 만들기",
            image: image6
        }
    ]

    const [notices, setNotices] = useState([]);
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        firebase.firestore().collection('notices').orderBy('created_at', 'desc')
            .limit(3).onSnapshot(snapshot => {
            setNotices(snapshot.docs.map(value => {
                return {
                    id: value.id,
                    ...value.data()
                };
            }));
        });
        firebase.firestore().collection('reviews').orderBy('created_at', 'desc')
            .limit(3).onSnapshot(snapshot => {
            setReviews(snapshot.docs.map(value => {
                return {
                    id: value.id,
                    ...value.data()
                }
            }));
        });
    }, []);

    return (
        <>
            <div id="main-jumbotron" className="jumbotron-fluid">
                <div className="container text-white text-center" style={{paddingTop: 150}}>
                    <h1 className="font-weight-bold mb-4">재무설계를 단번에 이해할 수 있는<br/>
                        필독 컨텐츠, 재무설계 필요성!</h1>
                    <p>경쟁은 심해지고 퇴직은 빨리지며 노년은 길어지고 모든게 급변하는 시대<br/>
                        달라지는 삶의 패턴과 재무설계에 대한 모든 것을 이해하기 쉽게 요약하였습니다</p>
                </div>
            </div>
            <div className="container py-5">
                <section className="py-5 mb-3">
                    <header className="text-center mb-5">
                        <h2 className="font-weight-bold">회사 소개</h2>
                    </header>

                    <div className="text- ">
                        <p className="paragraph">
                            <b>골든타임에셋프렌즈</b>는 다양한 금융정보와 객관적 금융상품 비교 및 각 분야별 금융전문가들의 오랜 경험과 노하우를 바탕으로 남다른 맞춤형 컨설팅을 약속드리며,
                            고객 만족을 위해 최선을 다하겠습니다.
                        </p>
                        <p className="paragraph">
                            <b>재무설계</b>는 인생 전반에 걸친 재무목표를 정하고 현재와 미래에 발생할 수 있는 소득을 예측하여 장기적이고 전략적으로 자산을 운용하고 관리하는 계획입니다.
                        </p>
                        <p className="paragraph">
                            그렇기에 실제 컨설팅을 진행함에 있어 설계사의 풍부한 경험과 노하우를 필요로 하는 전문 분야입니다.<br/>
                            <b>골들타임에셋프렌즈</b>는 그 간에 경험과 노하우로 최적의 재무컨설팅을 보여드리겠습니다.
                        </p>
                    </div>
                </section>

                <div className="py-3 mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card border-radius-0 border-0 shadow mb-5">
                                <div id="card-1" className="w-100 d-flex justify-content-center align-items-center">
                                    <h1 className="font-weight-bold text-white">재무상담</h1>
                                </div>
                                <div className="text-center p-4">
                                    <p>
                                        <b>당신의 목표는 무엇입니까?</b><br/>
                                        좋은 차, 좋은 집, 별장에서의 휴가<br/>
                                        원하는 모든 꿈을 실현시키기 위해서는<br/>
                                        경제적인 요소가 뒷받침되어야 합니다.
                                    </p>
                                    <p>
                                        소득은 일정하지만<br/>
                                        연령에 따라 씀씀이는 달라지고<br/>
                                        상황에 따라 지출은 늘어납니다.
                                    </p>
                                    <p>
                                        어떤 상황에서도<br/>
                                        품위를 지킬 수 있는 여유,<br/>
                                        넉넉한 자산에서 시작합니다.<br/>
                                        돈은 어떻게 버는지 보다,<br/>
                                        어떻게 관리하는지가 더 중요합니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card border-radius-0 border-0 shadow">
                                <div id="card-2" className="w-100 d-flex justify-content-center align-items-center">
                                    <h1 className="font-weight-bold text-white">경제교육</h1>
                                </div>
                                <div className="p-4">
                                    <p>
                                        <b>개인재무설계(Personal Financial Planning)</b>란 개인의 재무목표와 인생목표를 파악하고 정보를 수집, 분석하여
                                        개인의
                                        삶의 목표를
                                        달성할 수
                                        있도록 재무설계안을 계획, 실행, 점검하는 모든 과정을 의미합니다.
                                    </p>
                                    <p>
                                        사람들은 저마다 다른 재무상태와 재무목표를 가지고 있습니다.<br/>
                                        그렇기 때문에 재무설계 솔류션 또한 모두 같아선 안됩니다.
                                    </p>
                                    <p>
                                        <b>골든타임에셋프렌즈</b>에서는 고객님에게 최적화된 솔루션을 제공하여 개개인의 상황에 맞는 최고의 재무설계를 도와드립니다.
                                    </p>
                                    <p>
                                        재무설계는 마치 의사가 환자를 진료하고 질병을 예방해 주는 것과 같습니다!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-light py-5 mb-5">
                <div className="container">
                    <header className="text-center mb-5">
                        <h2 className="font-weight-bold">프로젝트</h2>
                    </header>

                    <div className="row">
                        {projects.map(project =>
                            <div key={project.id} className="col-6 col-md-4">
                                <Link to={`/about/${project.id}`} className="project-card card mb-4">
                                    <div className="card-body text-center">
                                        <img src={project.image} alt="image" className="img-fluid"
                                             style={{height: 100, objectFit: 'contain'}}/>
                                        <h4 className="card-title font-weight-bold">{project.title}</h4>
                                        <small className="card-text text-muted">매월 선착순 10명</small>
                                    </div>
                                </Link>
                            </div>)}
                    </div>
                </div>
            </div>

            <div className="py-3" style={{backgroundColor: "#ffea92"}}>
                <div className="container">
                    <div className="py-5 mb-5">
                        <div className="row">
                            <div className="col-md-5">
                                <header className="d-flex align-items-baseline mb-4">
                                    <h4 className="font-weight-bold mr-2">공지사항</h4>
                                    <small><Link to={'/notice'} className="text-muted" href="#">더보기</Link></small>
                                </header>

                                <div>
                                    <>
                                        {notices.map((value, index) =>
                                            <div key={index + 1} className="d-flex justify-content-between mb-3"
                                                 style={{minWidth: 0}}>
                                                <div className="d-flex" style={{minWidth: 0}}>
                                                    <span className="mr-3">{index + 1}</span>
                                                    <Link to={`/notice/${value.id}`}
                                                          className="font-weight-bold text-dark text-truncate">{value.title}</Link>
                                                </div>
                                                <div className="text-muted text-right ml-3" style={{minWidth: 100}}>
                                                    <Moment
                                                        format={"YYYY-MM-DD"}
                                                        date={value.created_at.toDate()}/>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                </div>
                            </div>
                            <div className="col-md-5 offset-md-1">
                                <header className="d-flex align-items-baseline mb-4">
                                    <h4 className="font-weight-bold mr-2">리뷰</h4>
                                    <small><Link to={'/review'} className="text-muted" href="#">더보기</Link></small>
                                </header>

                                <div>
                                    <>
                                        {reviews.map((value, index) =>
                                            <div key={index} className="d-flex justify-content-between mb-3"
                                                 style={{minWidth: 0}}>
                                                <div className="d-flex" style={{minWidth: 0}}>
                                                    <span className="mr-3">{index + 1}</span>
                                                    <Link to={`/review/${value.id}`}
                                                          className="font-weight-bold text-dark text-truncate">{value.title}</Link>
                                                </div>
                                                <div className="d-flex ml-3">
                                                    <div className="text-muted text-right"
                                                         style={{minWidth: 50}}>{value.name[0]}*{value.name[value.name.length - 1]}</div>
                                                    <div className="text-muted text-right ml-2" style={{minWidth: 90}}>
                                                        <Moment
                                                            format={"YYYY-MM-DD"}
                                                            date={value.created_at.toDate()}/>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-5">
                <Consulting/>
            </div>
        </>
    );
};

export default Home;