import React from "react";
import ScrollToTop from 'react-router-scroll-top';
import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";
import Home from "../pages/Home/Home";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Apply from "../pages/Apply/Apply";
import Admin from "../pages/Admin/Admin";
import About from "../pages/About/About";
import Notice from "../pages/Admin/Notice";
import NoticeCreate from "../pages/Admin/NoticeCreate";
import NoticeView from "../pages/Notice/NoticeView";
import Review from "../pages/Admin/Review";
import ReviewCreate from "../pages/Admin/ReviewCreate";
import ReviewView from "../pages/Review/ReviewView";
import ReviewList from "../pages/Review/ReviewList";
import ReviewCreateUser from "../pages/Review/ReviewCreate";
import NoticeList from "../pages/Notice/NoticeList";

const Router = () => {
    return (
        <>
            <BrowserRouter>
                <ScrollToTop>

                <Header/>

                <Switch>
                    <Route path={'/'} component={Home} exact/>
                    <Route path={'/apply'} component={Apply} exact/>

                    <Route path={'/admin'} component={Admin} exact/>
                    <Route path={'/admin/notice'} component={Notice} exact/>
                    <Route path={'/admin/notice/create'} component={NoticeCreate} exact/>
                    <Route path={'/admin/review'} component={Review} exact/>
                    <Route path={'/admin/review/create'} component={ReviewCreate} exact/>

                    <Route path={'/notice'} component={NoticeList} exact/>
                    <Route path={'/notice/:id'} component={NoticeView} exact/>

                    <Route path={'/review'} component={ReviewList} exact/>
                    <Route path={'/review/create'} component={ReviewCreateUser} exact/>
                    <Route path={'/review/:id'} component={ReviewView} exact/>

                    <Route path={'/about/:tag'} component={About} exact/>
                </Switch>

                <Footer/>

                </ScrollToTop>
            </BrowserRouter>
        </>
    );
};

export default Router;