import React, {useEffect, useState} from "react";
import AdminNav from "./AdminNav";
import {Link} from "react-router-dom";
import firebase from "firebase";

const Review = () => {
    const [reviews, setReviews] = useState([]);

    const deleteReview = reviewId => {
        if(window.confirm("리뷰를 삭제하시겠습니까?"))
            firebase.firestore().collection('reviews').doc(reviewId).delete()
                .then(() => {
                    alert("리뷰를 삭제했습니다.");
                })
    };

    useEffect(() => {
        firebase.firestore().collection('reviews').orderBy('created_at', 'desc')
            .onSnapshot(snapshot => {
            const newReviews = snapshot.docs.map(value => {
                return {
                    id: value.id,
                    ...value.data()
                };
            });

            setReviews(newReviews);
        })
    }, []);

    return (
        <>
            <AdminNav/>

            <div className="container">
                <header className="mb-4">
                    <Link className="btn btn-primary" to={'/admin/review/create'}>새로운 리뷰</Link>
                </header>
                <table className="table">
                    <thead>
                    <tr>
                        <th>No.</th>
                        <th>title</th>
                        <th>created_at</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {reviews.map((value, index) =>
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <Link to={`/review/${value.id}`}>
                                    {value.title}
                                </Link>
                            </td>
                            <td>{value.created_at.toDate().toDateString()} {value.created_at.toDate().toTimeString()}</td>
                            <td><i className="fas fa-trash text-danger pointer" onClick={() => deleteReview(value.id)}/></td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Review;