import React from 'react';
import "./assets/css/style.scss";
import Router from "./router/Router";
import firebase from "firebase/app";
import {firebaseConfig} from "./firebase";

firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <>
      <Router/>
    </>
  );
}

export default App;
