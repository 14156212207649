import React from "react";
import logo from "../../assets/img/logo.png";

const Footer = () => {
    return (
        <>
            <footer className="border-top py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-5">
                            <div>
                                <small>
                                {/*<nav className="nav">*/}
                                {/*    <a className="nav-link text-dark font-weight-bold pr-1 pl-0" href="#">회사소개</a>*/}
                                {/*    <a className="nav-link text-dark font-weight-bold pr-1" href="#">이용약관</a>*/}
                                {/*    <a className="nav-link text-dark font-weight-bold pr-1" href="#">개인정보처리방침</a>*/}
                                {/*    <a className="nav-link text-dark font-weight-bold pr-1" href="#">법적책임</a>*/}
                                {/*    <a className="nav-link text-dark font-weight-bold pr-1" href="#">운영정책</a>*/}
                                {/*    <a className="nav-link text-dark font-weight-bold pr-1" href="#">사진촬영안내</a>*/}
                                {/*</nav>*/}
                                </small>
                            </div>
                            <div>
                                <small>
                                    서울특별시 강서구 마곡중앙4로 10, 715-1호(마곡동그랑트윈타워) <span className="mx-2">|</span> 대표이사 조희원<br/>
                                    <span className="mr-3">사업자번호 : 108-86-17610</span>통신판매번호 : 2020-서울강서-3758 호
                                </small>
                            </div>
                        </div>
                        <div className="col-md-4 px-5 px-md-3 text-center">
                            <img src={logo} alt="logo" className="img-fluid" style={{maxWidth: 300, width: '100%'}}/>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;