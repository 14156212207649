import React, {useEffect, useState} from "react";
import firebase from "firebase";
import {Link} from "react-router-dom";
import Moment from "react-moment";

const NoticeList = () => {

    const [notices, setNotices] = useState([]);

    useEffect(() => {
        firebase.firestore().collection('notices').orderBy('created_at', 'desc')
            .onSnapshot(snapshot => {
            const newNoticeList = snapshot.docs.map(value => {
                return {
                    id: value.id,
                    ...value.data()
                }
            });

            setNotices(newNoticeList);
        })
    }, []);

    return (
        <>
            <div className="container py-5">
                <header className="text-center mb-5">
                    <h3 className="font-weight-bold">공지사항</h3>
                </header>

                <section style={{maxWidth: 600, margin: 'auto'}}>
                    {notices.map((value, index) => {
                        return (
                            <>
                                <div key={index + 1} className="d-flex justify-content-between mb-3" style={{minWidth: 0}}>
                                    <div className="d-flex" style={{minWidth: 0}}>
                                        <span className="mr-3">{index + 1}</span>
                                        <Link to={`/notice/${value.id}`}
                                              className="font-weight-bold text-dark text-truncate">{value.title}</Link>
                                    </div>
                                    <div className="text-muted text-right ml-3" style={{minWidth: 100}}>
                                        <Moment
                                            format={"YYYY-MM-DD"}
                                            date={value.created_at.toDate()}/>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </section>
            </div>
        </>
    );
};

export default NoticeList;