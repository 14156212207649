import React, {useState} from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import AdminNav from "./AdminNav";
import {convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";

const ReviewCreate = () => {
    const [title, setTitle] = useState('');
    const [name, setName] = useState('');

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(),
    );

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
                reject(error);
            };
        })
    }

    const writeReview = e => {
        e.preventDefault();

        const contentState = editorState.getCurrentContent();

        firebase.firestore().collection('reviews').add({
            title,
            name,
            content: JSON.stringify(convertToRaw(contentState)),
            created_at: new Date()
        })
            .then(() => {
                window.location = '/admin/review';
            });
    }

    return (
        <>
            <AdminNav/>

            <div className="container py-3">
                <header className="mb-4">
                    <h3 className="font-weight-bold text-center">리뷰 작성</h3>
                </header>
                <form onSubmit={writeReview}>
                    <div className="form-group">
                        <input type="text" className="form-control" value={title}
                               onChange={e => setTitle(e.currentTarget.value)} placeholder={"title"} required/>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" value={name}
                               onChange={e => setName(e.currentTarget.value)} placeholder={"name"} required/>
                    </div>
                    <div className="form-group">
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={e => setEditorState(e)}
                            toolbar={{
                                image: {
                                    uploadCallback: async (e) => {

                                        const result = await getBase64(e);

                                        return Promise.resolve({
                                            data: {
                                                link: result
                                            }
                                        });
                                    },
                                    previewImage: true,
                                    defaultSize: {
                                        width: '100%'
                                    }
                                }
                            }}
                        />
                    </div>
                    <button type={"submit"} className="btn btn-primary">작성하기</button>
                </form>
            </div>
        </>
    );
};

export default ReviewCreate;