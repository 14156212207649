import React from "react";
import {Link} from "react-router-dom";
import logo from "../../assets/img/로고 png/심볼_컬러.png";

const Consulting = () => {
    return (
        <>
            <div className="p-4 p-md-5 mb-5 position-relative bg-light">
                <div className="position-relative" style={{zIndex: 1}}>
                    <div className="mb-5">
                        <h2 className="font-weight-bold">상담이 필요하세요?</h2>
                        <p>지금 바로 온라인으로 상담 신청하고, 상담 받아보세요!</p>
                    </div>
                    <Link to={'/apply'} className="btn btn-primary">상담 신청하기</Link>
                </div>

                <div className="position-absolute w-100 h-100 overflow-hidden" style={{left: 0, top: 0}}>
                    <img src={logo} alt="logo" className="img-fluid position-absolute" style={{width: 188, right: -53, top: '50%', transform: 'translateY(-50%)'}}/>
                </div>
            </div>
        </>
    );
};

export default Consulting;