import React, {useState} from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import {EditorState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import AdminNav from "./AdminNav";

const NoticeCreate = () => {
    const [title, setTitle] = useState('');
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(),
    );

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
                reject(error);
            };
        })
    }

    const writeNotice = e => {
        e.preventDefault();

        const contentState = editorState.getCurrentContent();

        firebase.firestore().collection('notices').add({
            title,
            content: JSON.stringify(convertToRaw(contentState)),
            created_at: new Date()
        })
            .then(() => {
                window.location = '/admin/notice';
            });
    }

    return (
        <>
            <AdminNav/>

            <div className="container py-3">
                <header className="mb-4">
                    <h3 className="font-weight-bold text-center">공지사항 작성</h3>
                </header>
                <form>
                    <div className="form-group">
                        <input type="text" className="form-control" value={title}
                               onChange={e => setTitle(e.currentTarget.value)} required/>
                    </div>
                    <div className="form-group">
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={e => setEditorState(e)}
                            toolbar={{
                                image: {
                                    uploadCallback: async (e) => {

                                        const result = await getBase64(e);

                                        return Promise.resolve({
                                            data: {
                                                link: result
                                            }
                                        });
                                    },
                                    previewImage: true,
                                    defaultSize: {
                                        width: '100%'
                                    }
                                }
                            }}
                        />
                    </div>
                    <button className="btn btn-primary" onClick={writeNotice}>작성하기</button>
                </form>
            </div>
        </>
    );
};

export default NoticeCreate;