import React, {useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import {Link} from "react-router-dom";
import AdminNav from "./AdminNav";

const Notice = () => {
    const [notices, setNotices] = useState([]);

    const deleteNotice = noticeId => {
        if(window.confirm("공지를 삭제하시겠습니까?"))
            firebase.firestore().collection('notices').doc(noticeId).delete()
                .then(() => {
                    alert("공지를 삭제했습니다.");
                })
    };

    useEffect(() => {
        firebase.firestore().collection('notices').orderBy('created_at', 'desc')
            .onSnapshot(snapshot => {
            const newNotices = snapshot.docs.map(value => {
                return {
                    id: value.id,
                    ...value.data()
                };
            });

            setNotices(newNotices);
        })
    }, []);

    return (
        <>
            <AdminNav/>

            <div className="container">
                <header className="mb-4">
                    <Link className="btn btn-primary" to={'/admin/notice/create'}>새로운 공지사항</Link>
                </header>
                <table className="table">
                    <thead>
                    <tr>
                        <th>No.</th>
                        <th>title</th>
                        <th>created_at</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {notices.map((value, index) =>
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <Link to={`/notice/${value.id}`}>
                                    {value.title}
                                </Link>
                            </td>
                            <td>{value.created_at.toDate().toDateString()} {value.created_at.toDate().toTimeString()}</td>
                            <td><i className="fas fa-trash text-danger pointer" onClick={() => deleteNotice(value.id)}/></td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Notice;