import React, {useState} from "react";
import firebase from "firebase/app";
import "firebase/firestore";

const Apply = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [method, setMethod] = useState('');
    const [date, setDate] = useState(new Date());
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [talk, setTalk] = useState('');

    const handleSubmit = e => {
        e.preventDefault();

        firebase.firestore().collection('applicants').add({
            name,
            phone,
            email,
            method,
            date,
            address,
            addressDetail,
            talk,
            paid: false,
            done: false,
        })
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.error(err);
            })
        console.log(name, phone, email, method, date, address, addressDetail, talk);
    };

    return (
        <>
            <div className="container pb-4 mb-5">

                <hr className="mb-4"/>

                <header className="text-center">
                    <h3 className="font-weight-bold">상담신청</h3>
                    {/*<small className="text-muted">Lorem ipsum</small>*/}
                </header>

                <hr className="mt-4 mb-5"/>

                <div className="row">
                    <div className="col-md-4 border-right">
                        <header className="mb-4">
                            <h4 className="font-weight-light">Contact Info</h4>
                        </header>
                        <p className="text-muted">
                            Address : 서울특별시 강서구 마곡동 799-7<br/>
                            마곡그랑트윈타워 A동 715-1호<br/>
                            Phone : 02-3665-1367<br/>
                            Email : assetfriends@naver.com
                        </p>
                    </div>
                    <div className="col-md-8">
                        <form className="px-3" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label className="font-weight-bold" htmlFor="input-name">이름</label>
                                <input type="text" className="form-control" id="input-name" style={{maxWidth: 300}}
                                       value={name} onChange={e => setName(e.currentTarget.value)} required/>
                            </div>
                            <div className="form-group">
                                <label className="font-weight-bold" htmlFor="input-phone">연락처</label>
                                <input type="text" className="form-control" id="input-phone" style={{maxWidth: 300}}
                                       value={phone} onChange={e => setPhone(e.currentTarget.value)} required/>
                            </div>
                            <div className="form-group">
                                <label className="font-weight-bold" htmlFor="input-email">이메일</label>
                                <input type="email" className="form-control" id="input-email" style={{maxWidth: 300}}
                                       value={email} onChange={e => setEmail(e.currentTarget.value)} required/>
                            </div>
                            {/*<div className="form-group">*/}
                                {/*<label className="font-weight-bold" htmlFor="input-phone">상담방법</label>*/}
                                {/*<select className="form-control" id="select-method"*/}
                                {/*        value={method} onChange={e => setMethod(e.currentTarget.value)} required>*/}
                                {/*    <option value="">--- 상담 방법 ---</option>*/}
                                {/*    {["사무실 내방 : 20만원", "전화상담 : 20만원", "출장상담 : 30만원"].map((v, i) =>*/}
                                {/*        <option key={i} value={v}>*/}
                                {/*            {v}*/}
                                {/*        </option>)}*/}
                                {/*</select>*/}
                                {/*{["사무실 내방 : 20만원", "전화상담 : 20만원", "출장상담 : 30만원"].map((v, i) =>*/}
                                {/*    <div key={i} className="form-check">*/}
                                {/*        <input className="form-check-input" type="radio" id={`radio-method-${i}`} name="method"*/}
                                {/*               value={method} onChange={e => setMethod(e.currentTarget.value)} required/>*/}
                                {/*        <label className="form-check-label" htmlFor={`radio-method-${i}`}>*/}
                                {/*            {v}*/}
                                {/*        </label>*/}
                                {/*    </div>)}*/}
                            {/*</div>*/}
                            {/*<p className="text-muted">상담료 입금계좌 : 우리은행 1005-701-552137 (주)에듀머니</p>*/}

                            <div className="form-group">
                                <label className="font-weight-bold" htmlFor="input-date">상담희망일</label>
                                <input type="date" className="form-control" id="input-date"
                                       value={date} onChange={e => setDate(e.currentTarget.value)} required/>
                            </div>
                            <div className="form-group">
                                <label className="font-weight-bold" htmlFor="input-address">주소</label>
                                <input type="text" className="form-control" id="input-address"
                                       value={address} onChange={e => setAddress(e.currentTarget.value)} required/>
                                <input type="text" className="form-control" id="input-address-detail"
                                       value={addressDetail} onChange={e => setAddressDetail(e.currentTarget.value)}
                                       required/>
                            </div>
                            <div className="form-group">
                                <label className="font-weight-bold" htmlFor="input-etc">하고싶은말</label>
                                <textarea rows={5} className="form-control" id="input-etc"
                                          value={talk} onChange={e => setTalk(e.currentTarget.value)}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">상담신청</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Apply;