import React from "react";
import {useParams} from "react-router-dom";
import Consulting from "../../components/Consulting/Consulting";
import "./style.scss"

const About = () => {
    const {tag} = useParams();

    const data = {
        "1": {
            title: "결혼자금 만들기 노하우",
            image: "https://images.unsplash.com/photo-1567022936601-6f279a36e5e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
            content: <>
                <h5>01. 사회생활을 시작하면 바로 종잣돈을 모으자.</h5>
                <p>
                    사회생활을 시작하면, 결혼준비를 위한 종잣돈을 바로 모으기 시작하는 것이 중요합니다. 결혼에 대한 목표가 없더라도, 모아진 종잣돈은 주택마련을 하거나 다른 목표자금을 위해서 쓰일 수 도 있습니다. 단기간에 모을 돈과 중장기적으로 리스크를 줄이면서 수익률을 올릴 수 있는 투자계획을 철저하게 세워야 합니다.
                </p>

                <h5>02. 결혼비용에 대한 조사와 미리 준비를 해놓는다.</h5>
                <p>
                    결혼비용은 미리 조사를 통해 예비부부들의 상황을 고려하여 예산에 대한 명확한 준비가 필요합니다. 결혼식날짜가 임박하여 준비를 하게 되면, 급하게 비싼 비용으로 준비를 하게 될 수 도 있기 때문에 웨딩사진 촬영 등 미리 준비할 것은 미리하고 예식장 비용도 미리 카드 등으로 결제 후 분할로 갚아나가면 한 번에 목돈이 지출되는 것을 줄이고 비용부담을 없앨 수 있습니다.
                </p>

                <h5>03. 단 중기에 적합한 금융상품을 선택하여 돈을 모으자.</h5>
                <p>
                    결혼준비는 단기적으로 준비하는 경우가 많습니다. 대체적으로 결혼자금은 1년 혹은 2~3년 내에 필요하므로, 무리하게 수익을 추구하는 것보다 안전하게 단기 재테크를 통해 자금을 만들어야 합니다. 또한 연금과 같이 장기 금융상품으로 준비하다보면 중간에 손해를 보고 해약하게 되는 수 있으니 기간과 목적에 맞는 금융상품을 선택해 돈을 모아나가야 합니다.
                </p>
            </>
        },
        "2": {
            title: "내 집 마련하기",
            image: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
            content: <>
                <h5>01. 국가 정책과 제도를 활용하자.</h5>
                <p>부동산정책은 국가에서 가장 시시각각 변하는 정책입니다. 신혼부부, 무주택자들을 위한 정부의 지원정책이 계속해서 나오고 있고, 본인에게 유리한 지원정책을 적극 활용해야 됩니다. 맞춤형 정책에 대한 정보를 골든타임 에셋프렌즈에서 제공해 드립니다.</p>

                <h5>02. 평소에 신용도 관리와 대출을 적절히 활용한다.</h5>
                <p>내 집 마련을 위해 대출은 어쩔 수 없이 이용해야 하는 필수코스가 되어 버렸습니다. 평소에 신용도 관리를 잘해야지만 낮은 이율로 대출을 받을 수 있으며, 대출한도도 더 크게 받을 수 있습니다. 주택담보비율이 점점 낮아지고 있는 추세라 대출한도와 신용대출을 어떻게 활용하며, 부담되지 않는 선에서 이자를 낼 수 있도록 철저한 지출 계획을 세워야 합니다.</p>

                <h5>03. 주택경기와 매입 시기를 위한 목돈마련 준비.</h5>
                <p>대출계획을 통해 한도를 산출하고 대출금액이 선정되었다면, 남은 목표금액은 어떻게 해서 모아야하는지 철저한 계획이 필요합니다. 주택자금마련은 단 시간에 되는 목표가 아니기 때문에 중장기적으로 투자를 어떻게 해나가야 하는지 계획을 세우는 것이 정말 중요합니다. 또한 부동산 경기와 부동산정책을 참고하여 유리한 기회를 활용하여 저렴하게 매입하는 것이 중요합니다.</p>
            </>
        },
        "3": {
            title: "보장자산 만들기",
            image: "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
            content: <>
                <h5>01. 보험은 RISK를 관리하는 상품이다!</h5>
                <p>보험은 우리가 돈을 벌기위해 하는 투자상품이 아닙니다. 내가 살면서 생길 수 있는 RISK에 대한 대비입니다. 그럼 살면서 생길 수 있는 RISK는 어떤게 있을까요?
                    건강하게 오래살 수 도 있고, 일찍 죽을 수도 있고, 질병과 사고에 걸릴 수도 있습니다. 이 3가지 경우의 수 중 1가지에는 꼭 해당되게 됩니다. 그렇기 때문에 보험이라는 것은 반드시 필요하며, 오래사는 것에 대한 대비는 연금, 일찍 죽는 것에 대한 대비는 종신보험, 질병과 사고는 기타 여러 보장성보험들로 대비를 할 수 가 있습니다. 주어진 소득 내에서 일부는 반드시 보장자산에도 투자를 해야 내가 하고 있는 여러 가지 재테크들도 성공할 수 가 있습니다.</p>

                <h5>02. 젊을 때 확실하게 설계하자.</h5>
                <p>보험료라는 것은 걸릴 확률이 높을수록 올라가게 되어있습니다. 나이가 들 수 록 질병에 걸릴 확률은 높아지니 자연스럽게 보험료는 올라가고, 같은 보험료로 받을 수 있는 보장은 적어질 수 밖에 없습니다. 또한 아프게 되면 그 부분은 보험에서 보장을 받을 수 없기 때문에 나이가 조금이라도 젊을 때 내 보험에 대한 확실한 설계가 필요합니다.</p>

                <h5>03. 나에게 유리한 납입기간과 갱신/비갱신인지를 확인하자.</h5>
                <p>보험은 보험료 납입기간이 길수록 보험료는 싸지고, 납입기간이 짧을수록 보험료는 비싸지게 됩니다. 자신의 소득과 상황을 면밀히 분석하여 보험납입기간을 설정해야만 오래도록 유지하며 정말 보험이 필요한시기에 보장을 받을 수 있습니다. 또한 갱신인지 비갱신인지 확인해 볼 필요가 있으며 젊었을 때 갱신형이 싸다고 가입을 해놓으면 정작 보험이 필요한 노후에 보험료가 올라 보장을 받지 못하고, 해지하게 되는 경우도 생기니 본인에게 맞는 맞춤형 설계가 필요합니다. 골든타임에셋프렌즈는 본인에게 맞는 최적의 보험설계를 해드립니다.</p>
            </>
        },
        "4": {
            title: "은퇴 노후 자금 만들기",
            image: "https://images.unsplash.com/photo-1496938461470-aaa34930e2d7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
            content: <>
                <h5>01. 의료비에 대한 대비를 철저히 하자.</h5>
                <p>통계에 의하면 노후생활비 중 가장 큰 부분이 의료비로 지출되고 있습니다. 고령화로 인한 질병치료비가 증가함에 따라 의료비 부분에 대한 대비는 철저히 해야 됩니다. 젊었을 때부터 보장성보험(종신보험, 건강보험, 실비보험 등)으로 주요 질병과 의료비에 대해 대비를 확실히 해야할 필요가 있습니다.</p>

                <h5>02. 소득이 없는 상황에 대한 대비, 장기 생존에 대한 리스크 대비가 필요.</h5>
                <p>의료기술의 발달로 점점 수명은 연장되는데 비해 정년은 짧아지고, 소득 없는 기간이 늘어남에 따라 본인만의 소득시스템을 젊었을 때부터 만들어 놓아야 합니다. 국민연금, 퇴직금, 개인연금 3단 연금을 통한 최저생계보장, 기본생활보장을 잘 계획해 놓고, 임대소득, 이자소득, 배당소득 등 여러 부분에서 소득이 발생할 수 있도록 본인만의 소득시스템을 만들어 놓아야합니다.</p>

            </>
        },
        "5": {
            title: "자녀교육자금 마련계획",
            image: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1309&q=80",
            content: <>
                <h5>01. 미리 장기적인 계획을 세워 준비한다.</h5>
                <p>자녀가 태어날 때부터 자녀에 대학 여러 가지 비용은 발생하지만, 자녀가 본격적으로 학교에 들어가고 중, 고등학생이 되면 교육비에 대한 부담은 늘어나게 됩니다. 한 가정에서 벌 수 있는 소득에 대한 한계가 있기 때문에 비용이 적게 나가는 자녀가 어릴 적부터 교육비에 대한 목적자금 마련을 계획해야 됩니다.</p>

                <h5>02. 지출로 할 부분과 저축으로 할 부분을 명확히 구분하자.</h5>
                <p>자녀에게 들어가는 부분은 비단 교육비 부분만은 아닙니다. 식비, 병원비, 여러 가지 사야되는 자녀 물건들 등등 들어가는 비용은 정말 많아지게 됩니다. 이 중 내가 단기적으로 들어가는 비용과, 장기적으로 준비해야 되는 비용을 구분할 필요가 있습니다. 단기적으로 들어가는 비용 등은 가계부 정리를 통해 매달 지출에서 일부분을 떼어 계획적으로 소비할 필요가 있으며, 중장기적으로 들어가는 교육비부분에 대해서는 저축을 통해 준비할 필요가 있습니다.</p>

                <h5>03. 생활비 중 무리가 가지 않도록 목표금액을 설정.</h5>
                <p>자녀교육에 대한 교육 욕심에 재정에 무리가 가는 경우가 많습니다. 이 때문에 잘 유지해온 보험 상품을 해약한다든가, 대출을 받는 상황, 기존의 투자 상품을 손실보고 해약하는 경우도 많이 일어납니다. 가능한 소득의 20%를 넘기지 않는 한도 내에서 자녀교육비를 책정하는 것이 좋습니다.</p>

            </>
        },
        "6": {
            title: "종자돈 1억 만들기 실천 노하우",
            image: "https://images.unsplash.com/photo-1561414927-6d86591d0c4f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1266&q=80",
            content: <>
                <h5>01. 현재 자기 상황을 정확하게 파악하자!</h5>
                <p>우리는 모두 직업도 다르고 급여도 다르고, 주어진 환경도 다릅니다. 내가 얼마를 벌고 있으며, 그 중 나는 얼마를 저축할 수 있고 지출을 할지 명확하게 분석을 해봐야 포트폴리오를 알맞게 짤 수 가 있습니다. 그리고 계속 지금 하는 일을 해나갈지, 이직을 할지 이런 것들에 대한 명확한 계획을 세워야 투자에 대한 포트폴리오도 정확하게 나오게 됩니다.</p>

                <h5>02. 통장을 쪼개고 지출관리를 한다.</h5>
                <p>대부분은 내가 한 달에 얼마를 쓰게 되는지 모르고 있습니다. 자동으로 카드로 결제가 되고, 급여통장에서 공과금이 자동 이체되고, 남은 돈을 쓰다보면 금방 쓰지도 않았는데 월급이 스쳐지나가는 느낌을 받지 않으셨나요? 재무상담을 통해 지출 분석을 명확히 하고 줄일 수 있는 부분을 줄여야 10만원이라도 더 저축할 여유가 생깁니다. 숨은 돈을 찾고 통장 쪼개기를 통한 올바른 지출습관을 찾아드립니다.</p>

                <h5>03. 기간에 맞는 투자상품을 선택하자.</h5>
                <p>돈을 모으는데 있어서 수익률보다 중요한 부분은 정확한 투자상품을 선택하는 일입니다. 단기 결혼자금을 모으는데 노후 연금상품으로 모아선 안되며, 마찬가지로 연금을 위해 모으는돈을 적금으로만 모으고 있어서도 돈이 모이질 않습니다. 수익률 1%보다 더 중요한 것은 일단 기간에 따른 정확한 투자포트폴리오 설계입니다. 이런 부분에 대한 정확한 상담은 골든타임에셋프렌즈에서 도와드리겠습니다.</p>

                <h5>04. RISK관리</h5>
                <p>모두가 투자를 두려워하는 가장 큰 이유는 손실을 볼 가능성이 있기 때문입니다. 투자하는데 있어서 가장 중요한 부분은 바로 손실관리입니다. 돈을 잃지 않는 투자방법을 안다면 누구나 다 꾸준하고 만족스러울 만한 수익을 올릴 수 있습니다. 골든타임에셋프렌즈는 포트폴리오의 체계적 분산과, 정확한 기업분석, 국가분석, 세계경제흐름의 분석 등 여러분이 꼭 필요한 정보에 대해서 제공을 해드립니다.</p>
            </>
        },
    };

    return (
        <>
            <div className="jumbotron-fluid" style={{background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${data[tag].image}') center center`, backgroundSize: 'cover', height: 300}}>
                <div className="container px-5 pb-4 text-white d-flex align-items-end h-100">
                    <h2 className="font-weight-bold mb-4">{data[tag].title}</h2>
                </div>
            </div>
            <div className="container mb-5">
                <section id="about-paragraph" className="pb-3 mb-2">
                    <div className="card border-radius-0">
                        <div className="card-body p-3 p-md-5">
                            {data[tag].content}
                        </div>
                    </div>
                </section>

                <Consulting/>
            </div>
        </>
    );
};

export default About;