import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import firebase from "firebase";
import {convertFromRaw, Editor, EditorState, Entity} from "draft-js";

const ReviewView = () => {

    const {id} = useParams();

    const [title, setTitle] = useState('');
    const [name, setName] = useState('');
    const [createdAt, setCreatedAt] = useState(new Date());

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(),
    );

    function mediaBlockRenderer(block) {
        if (block.getType() === 'atomic') {
            return {
                component: Media,
                editable: false
            };
        }
        return null;
    }

    const Image = (props) => {
        return <img className="img-fluid" src={props.src} alt={props.src}/>;
    };

    const Media = (props) => {

        const entity = Entity.get(props.block.getEntityAt(0));

        const {src} = entity.getData();
        const type = entity.getType();

        let media = <hr/>;
        if (type === 'IMAGE') {
            media = <Image src={src} />;
        }

        return media;
    };

    useEffect(() => {
        firebase.firestore().collection('reviews').doc(id).onSnapshot(snapshot => {
            const {title, name, content, created_at} = snapshot.data();
            setTitle(title);
            setName(name);
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(content))));
            setCreatedAt(created_at.toDate());
        })
    }, []);

    return (
        <>
            <div className="container py-5">
                <header className="text-center mb-5">
                    <h3 className="font-weight-bold">리뷰</h3>
                </header>

                <section>
                    <header className="mb-4">
                        <h3 className="font-weight-bold">{title}</h3>
                        <hr/>
                    </header>
                    <Editor editorState={editorState} blockRendererFn={mediaBlockRenderer} readOnly/>
                </section>
            </div>
        </>
    );
};

export default ReviewView;