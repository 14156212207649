import {Link} from "react-router-dom";
import React from "react";

const AdminNav = () => {
    return (
        <>
            <ul className="nav justify-content-center mb-4">
                <li className="nav-item">
                    <Link to={'/admin'} className="nav-link">Home</Link>
                </li>
                <li className="nav-item">
                    <Link to={'/admin/notice'} className="nav-link">Notice</Link>
                </li>
                <li className="nav-item">
                    <Link to={'/admin/review'} className="nav-link">Review</Link>
                </li>
            </ul>
        </>
    );
};

export default AdminNav;