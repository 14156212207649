import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const ReviewList = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("reviews")
      .orderBy("created_at", "desc")
      .onSnapshot((snapshot) => {
        const newReviewList = snapshot.docs.map((value) => {
          return {
            id: value.id,
            ...value.data(),
          };
        });

        setReviews(newReviewList);
      });
  }, []);

  return (
    <>
      <div className="container py-5">
        <header className="text-center mb-5">
          <h3 className="font-weight-bold">리뷰</h3>
        </header>

        <section style={{ maxWidth: 500, margin: "auto" }}>
        <div className="d-flex justify-content-end mb-4">
            <Link className="btn btn-primary" to={"/review/create"}>
              리뷰 작성
            </Link>
          </div>

          <section className="mb-4">
            {reviews.map((value, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="d-flex justify-content-between mb-3"
                    style={{ minWidth: 0 }}
                  >
                    <div className="d-flex" style={{ minWidth: 0 }}>
                      <span className="mr-3">{index + 1}</span>
                      <Link
                        to={`/review/${value.id}`}
                        className="font-weight-bold text-dark text-truncate"
                      >
                        {value.title}
                      </Link>
                    </div>
                    <div className="d-flex ml-3">
                      <div
                        className="text-muted text-right"
                        style={{ minWidth: 50 }}
                      >
                        {value.name[0]}*{value.name[value.name.length - 1]}
                      </div>
                      <div
                        className="text-muted text-right ml-2"
                        style={{ minWidth: 90 }}
                      >
                        <Moment
                          format={"YYYY-MM-DD"}
                          date={value.created_at.toDate()}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </section>
        </section>
      </div>
    </>
  );
};

export default ReviewList;
