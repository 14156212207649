import React, {useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import {Link} from "react-router-dom";
import AdminNav from "./AdminNav";

const Admin = () => {
    const [applicants, setApplicants] = useState([]);

    useEffect(() => {
        firebase.firestore().collection('applicants').onSnapshot(snapshot => {
            const newApplicants = snapshot.docs.map(value => ({
                id: value.id,
                ...value.data()
            }))
            setApplicants(newApplicants);
        })
    }, []);

    return (
        <>
            <AdminNav/>

            <div className="container-fluid mb-5">
                <table className="table">
                    <thead>
                    <tr>
                        <th>No.</th>
                        <th>이름</th>
                        <th>연락처</th>
                        <th>이메일</th>
                        <th>상담방법</th>
                        <th>상담희망일</th>
                        <th>주소</th>
                        <th>하고싶은말</th>
                        <th>상담비입금</th>
                        <th>상담완료</th>
                    </tr>
                    </thead>
                    <tbody>
                    {applicants.map((v, i) => {
                        const {name, phone, email, method, date, address, addressDetail, talk, paid, done} = v;
                        return (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{name}</td>
                                <td>{phone}</td>
                                <td>{email}</td>
                                <td>{method}</td>
                                <td>{date}</td>
                                <td>{address} {addressDetail}</td>
                                <td>{talk}</td>
                                <td>{paid ? "O" : "X"}</td>
                                <td>{done ? "O" : "X"}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Admin;