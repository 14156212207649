import React, { useState } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

const ReviewCreate = () => {
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
        reject(error);
      };
    });
  };

  const writeReview = (e) => {
    e.preventDefault();

    const contentState = editorState.getCurrentContent();

    firebase
      .firestore()
      .collection("reviews")
      .add({
        title,
        name,
        content: JSON.stringify(convertToRaw(contentState)),
        created_at: new Date(),
      })
      .then(() => {
        window.location = "/review";
      });
  };

  return (
    <>
      <div className="container py-3" style={{ maxWidth: 800 }}>
        <header className="mb-4">
          <h3 className="font-weight-bold text-center">리뷰 작성</h3>
        </header>
        <form onSubmit={writeReview}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
              placeholder={"리뷰 제목"}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              placeholder={"작성자"}
              required
            />
          </div>
          <div className="border-bottom mb-4">
            <Editor
              placeholder="리뷰를 작성해주세요"
              editorState={editorState}
              onEditorStateChange={(e) => setEditorState(e)}
              toolbar={{
                image: {
                  uploadCallback: async (e) => {
                    const result = await getBase64(e);

                    return Promise.resolve({
                      data: {
                        link: result,
                      },
                    });
                  },
                  previewImage: true,
                  defaultSize: {
                    width: "100%",
                  },
                },
              }}
            />
          </div>

          <div className="d-flex justify-content-between">
            <Link to={"/review"} className="btn btn-secondary text-white">목록으로</Link>
            <button type={"submit"} className="btn btn-primary">
              작성하기
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ReviewCreate;
