import React from "react";
import {Link} from "react-router-dom";
import "./style.scss";
import logo from "../../assets/img/logo.png"
import logo2 from "../../assets/img/logo2.png";


const Header = () => {
    return (
        <>
            <div className="container position-relative py-4">
                <nav className="py-2">
                    <div className="d-flex justify-content-center">
                        <div className="position-absolute text-dark" style={{left: 15}}>
                            <small>
                                <nav className="nav">
                                    <Link to={'/apply'}
                                          className="text-primary font-weight-bold mr-4">온라인<br/>상담신청</Link>
                                </nav>
                            </small>
                        </div>
                        <Link to="/" className="logo-link">
                            {/*<img src={logo2} alt="logo" className="brand-logo" style={{transform: 'scale(0.75)'}}/>*/}
                            <img src={logo} alt="logo" className="brand-logo"/>
                        </Link>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default Header;